<template>
  <div class="released">
    <div class="bidding_top">
      <div class="bidding_content">
        <div class="name">竞价销售</div>
        <!-- 发布竞价销售信息 -->
        <div class="release" @click="addClick">发布竞价销售信息</div>
      </div>
    </div>

    <div class="bidd">
      <div class="biddings">
        <!-- <particulars></particulars> -->
        <!-- <regulation></regulation> -->
        <!-- 竞价销售我发布的 -->
        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div class="item_goodsName" style="display: flex">
              <el-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  <span
                    v-for="(goodsName, index) in item.goodsCategoryList"
                    :key="index"
                    >{{ goodsName.goodsName }} &nbsp;
                    {{
                      index == item.goodsCategoryList.length - 1 ? "" : "|"
                    }}&nbsp;</span
                  >
                </div>
                <div>
                  <span
                    v-for="(goodsName, index) in item.goodsCategoryList"
                    :key="index"
                    >{{ goodsName.goodsName }} &nbsp;
                    {{
                      index == item.goodsCategoryList.length - 1 ? "" : "|"
                    }}&nbsp;</span
                  >
                </div>
              </el-tooltip>
              <div
                style="
                  line-height: 30px;
                  color: rgb(208, 17, 26);
                  margin-left: 20px;
                "
              >
                竞价日期：{{ item.biddingStartTime }} —
                {{ item.biddingEtartTime }}
              </div>
              <div
                style="line-height: 30px; margin-left: 10px; color: red"
                v-if="item.goodsStatus == 0 && item.auditStatus == 0"
              >
                待审核
              </div>
              <div
                style="line-height: 30px; margin-left: 10px; color: green"
                v-if="item.goodsStatus == 0 && item.auditStatus == 1"
              >
                审核通过
              </div>
            </div>
            <div style="display: flex">
              <div style="line-height: 40px; margin-right: 20px">
                <i class="hy-icon-shijian1"></i>

                {{ item.createTime }}
              </div>
              <div
                class="title_but"
                style="border: 1px solid #ccc; margin-bottom: 5px"
                @click.stop="closeDemand(item)"
                v-if="item.auditStatus == 0 && item.goodsState == 1"
              >
                关闭需求
              </div>
              <div
                class="title_but"
                style="color: red"
                v-if="item.goodsStatus == 0 && item.auditStatus == 2"
              >
                审核拒绝
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 1 && item.auditStatus == 1"
              >
                进行中
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 2 && item.auditStatus == 1"
                style="color: red"
              >
                已流拍
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 3 && item.auditStatus == 1"
              >
                拍卖成功
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 4 && item.auditStatus == 0"
                style="color: red"
              >
                已关闭
              </div>
            </div>
          </div>
          <div class="item_name">
            <div class="item_images">
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span v-if="item.priceType == 0">未知</span>
              <span v-if="item.priceType == 1">坑口价</span>
              <span v-if="item.priceType == 2">出厂价</span>
              <span v-if="item.priceType == 3">船板价</span>
              <span v-if="item.priceType == 4">平仓价</span>
              <span v-if="item.priceType == 5">车板价</span>
              <span v-if="item.priceType == 6">到厂价</span>
            </div>

            <div style="margin-left: 20px" class="price-count">
              竞价底价：
              <el-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  <span
                    v-for="(goodsItem, i) in item.goodsCategoryList"
                    :key="i"
                  >
                    <span>￥</span><span>{{ goodsItem.goodsPrice }}</span
                    >元/吨 &nbsp;
                    <span>数量</span>
                    <span>{{ goodsItem.number }}</span
                    >吨
                    <span v-if="i != item.goodsCategoryList.length - 1">|</span>
                  </span>
                </div>
                <span>
                  <span
                    v-for="(goodsItem, i) in item.goodsCategoryList"
                    :key="i"
                  >
                    <span style="color: #d0111a">￥</span
                    ><span style="color: #d0111a; font-size: 30px">{{
                      goodsItem.goodsPrice
                    }}</span
                    >元/吨 &nbsp;
                    <span>数量</span>
                    <span style="font-size: 30px; color: #d0111a">{{
                      goodsItem.number
                    }}</span
                    >吨
                    <span
                      v-if="i != item.goodsCategoryList.length - 1"
                      style="font-size: 26px"
                      >|</span
                    >
                  </span>
                </span>
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="item_num">
            <div>
              数量：<span>{{ item.stockCount }}(吨)</span>
            </div>
            <div>竞价开始日期：{{ item.baddingStartTime }}</div>
            <div>竞价结束日期：{{ item.baddingEtartTime }}</div>
          </div> -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-right: 40px;
            "
          >
            <div>
              <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
              <div class="place">产地：{{ item.originPlace }}</div>
            </div>

            <div
              style="
                display: flex;
                line-height: 50px;
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                padding: 10px 20px;
              "
              v-if="item.goodsStatus == 3"
            >
              <div><i class="hy-icon-jiage"></i> 中标价：</div>
              <div style="color: #d0111a">￥</div>
              <div v-for="(goods, i) in item.goodsCategoryList" :key="i">
                <span style="font-size: 30px; color: #d0111a">{{
                  goods.dealPrice
                }}</span>
                <span>/吨</span>
                <span v-if="i != item.goodsCategoryList.length - 1">丨</span>
              </div>
            </div>
          </div>
        </div>
        <div class="allpage" v-if="readlist.length">
          <el-pagination
            background
            :total="total"
            :page-size="size"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tradeGoods, setclose } from "@/api/public.js";
export default {
  data() {
    return {
      readlist: [],
      page: 1,
      size: 10,
      total: 0,
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    addClick() {
      if (!this.beforhandle("personalCenter:auctionSale:publish:release"))
        return;
      this.$router.push({
        path: "addBiddingGoods",
        query: {
          selectOrderType: 1,
          type: "竞价销售",
          title: "发布竞价销售信息",
        },
      });
    },
    async handleCurrentChange(val) {
      let config = {
        page: val,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 2,
        selectType: 2,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.total = res.data.total;
        this.readlist = list;
      }
    },
    async getlist() {
      let config = {
        page: this.page,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 2,
        selectType: 2,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.total = res.data.total;
        this.readlist = list;
      }
    },
    closeDemand(item) {
      if (!this.beforhandle("personalCenter:auctionSale:publish:close")) return;
      console.log(item.goodsId);
      this.$confirm("关闭后商品将无法竞拍", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setclose(item.goodsId).then((res) => {
            // console.log(res);
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "关闭成功!",
              });
              this.getlist();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消关闭",
          });
        });
    },
    ItemClick(item) {
      if (!this.beforhandle("personalCenter:auctionSale:publish:detail"))
        return;
      // this.$router.push("/details");
      this.$router.push({
        path: "/details",
        query: {
          goodsId: item.goodsId,
          status: item.goodsStatus,
          listType: item.listType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.released {
  max-width: 1200px;
  margin: 0 auto;
}

.bidd {
  background: white;
  padding: 10px;
  margin: 0 auto;
  .biddings {
    // width: 70%;
    .item {
      width: 100%;
      border: 1px solid black;
      padding: 10px;
      margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        .title_but {
          padding: 10px;
          cursor: pointer;
        }
        .item_goodsName {
          display: flex;
          line-height: 40px;
          :nth-child(1) {
            font-size: 18px;
            color: black;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 200px;
          }
        }
      }
      .item_name {
        display: flex;
        margin-top: 5px;

        .item_images {
          width: 100px;
          height: 24px;
          background: url("../../assets/images/tag.png") no-repeat;
          background-size: 100% 100%;
          color: white;
          padding-left: 3px;
          padding-top: 2px;
          margin-top: 10px;
        }
      }
      .item_num {
        :nth-child(1) {
          width: 300px;
        }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 20px;
      }
      .place {
        margin-top: 20px;
      }
      .price-count {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 500px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
}

.bidding_top {
  margin-top: 20px;
  width: 100%;
  background: #e5e5e5;

  .bidding_content {
    // width: 90%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding: 10px;
    .name {
      font-size: 20px;
      margin-top: 5px;
    }
    .release {
      height: 40px;
      width: 200px;
      text-align: center;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid #ccc;
      cursor: pointer;
      color: white;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
